.header {
    font-weight     : 300;
    font-size       : 18px;
    border-bottom   : 1px solid;
    font-family     : inherit;
    background-color: var(--home-background-lighter);
    border-radius   : 3px 3px 0 0;
}

.cancelButton {
    font-weight     : 300;
    margin          : 0;
    font-family     : inherit;
}

.modalButton {
    background-color: var(--blue-button-background);
    font-weight     : 300;
    margin          : 0;
    margin-left     : 15;
    font-family     : inherit;
}

.buttonParent {
    padding         : 5px 20px 20px 20px;
    /* width           : 100%; */
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: var(--home-background-lighter);
}

.buttonParent button{
    margin: 10px;
}

.modalContent {
    flex          : 1;
    display       : flex;
    flex-direction: column;
    overflow      : auto;
    padding       : 10;
    width: inherit;
}

.modalHelpLink {
    margin-left: 15px;
}