
.root {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
  /* background: transparent; */
}

.appBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
  background-color: var(--home-background);
}

.absoluteContent {
  position: absolute;
  left: var(--leftbar-width);
  top: 0;
  right: 0;
  bottom: 0;
}

.link {
  color: var(--link-color);
  cursor: pointer;
}