
.root {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: var(--header-height);
    color: var(--text-color);
    z-index: 10;
  }
  
  .row{
    display: flex;
    justify-content: center;
  }
  .logoParent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--leftbar-width);
    height: var(--header-height);
    background-color: var(--logo-background);
    pointer-events: auto;  
    border-right: 2px solid var(--accent-blue);
    /* border-bottom: 1px solid rgb(255,255,255, 0.03); */
  }
  
  .logoParent2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* padding: 5px; */
    border-radius: 10px;
    /* margin-right: 15px; */
    /* margin-bottom: 3px; */
    /* margin-left: 10px; */
  }
  
  .blueLogoText {
    font-weight: 200;
    font-size: 20px;
    padding-right: 5px;
    color: var(--accent-blue);
    border-right: 1px solid var(--logo-text-color);
    display: flex;
    align-items: center;
    height: 40px;
  }
  
  .whiteLogoText {
    font-weight: 200;
    font-size: 20px;
    color: var(--logo-text-color);
    margin-left: 5px;
  }
  
  .logo {
    height: 30px;
    margin-left: 10px;
  }
  
  /* .icon {
    margin-top: 15px;
    background-color: var(--home-background-lighter);
    border: 1px solid var(--home-background);
    display: flex;
    height: 40px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    pointer-events: all;
    cursor: pointer;
  } */

  
  .loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color); 
    flex: 1; 
    padding: 20;
  }
  
  .link {
    color: var(--link-color);
    cursor: pointer;
  }
  
  .searchBar {
    color: var(--text-color) !important;
    border: 1px solid white;
    border-radius: 10px;
    height: 40px;
    margin-top: 8px;
  }

  