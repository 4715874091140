.root {
    width         : 100%;
    /* height     : 100%; */
    display       : flex;
    flex-direction: column;
    position      : relative;
    /* overflow   : auto; */
}

.buttonParent {
    display: flex;
    padding: 15px 0 0 0;
}

.greyButton {
    /* background-color: var(--text-field-background); */
    background-color   : var(--blue-button-background);
    /* background-color: rgba(240, 242, 255, 0.05); */
    /* background-color: var(--main-bg-color); */
    /* background-color: rgb(40, 44, 52); */
    padding            : 10px 15px;
    padding-right      : 20px;
    /* margin-right    : 10px; */
    /* color           : var(--text-color); */
    color              : white;
    border-radius      : 4px;
    cursor             : pointer;
    font-weight        : 300;
    display            : flex;
    align-items        : center;
}

.plusIcon {
    margin-right: 7px;
}

.input {
    /* margin-right: 10px; */
    /* min-width: 200px; */
    width           : 100%;
    background-color: var(--text-field-background) !important;
    border          : 0 !important;
    /* border       : 1px solid transparent !important; */
    /* border-color : var(--accent-blue) !important;  */
    border-radius   : 5px !important;
    outline         : none !important;
    transition      : all 0.1s ease;
    color           : var(--text-color);
    border-radius   : 3px !important;
    min-width: 200px;
}

.input[type="text"] {
    background-color: var(--text-field-background) !important;
}

.input[type="password"] {
    background-color: var(--text-field-background) !important;
}

.dropdown {
    margin-right: 0 !important;
    width       : 100%;
}

/* .fieldItem:last-child .input {
    margin-right: 0 !important;
    width: 100%;
  } */

.input::placeholder {
    color: var(--placeholder-text-color);
}

.input::selection {
    background-color: rgb(85, 160, 240);
    color           : white;
}

.row {
    margin-bottom   : 15px;
    display         : flex;
    flex-direction  : column;
    background-color: var(--home-background-lighter);
    padding         : 15px;
    padding-top     : 0;
    padding-left    : 5px;
    border-radius   : 2px;
    position        : relative;
    /* overflow     : auto; */
    /* max-height   : 100%; */
}

.itemParent {
    flex         : 1;
    display      : flex;
    flex-wrap    : wrap;
    align-content: flex-start;
}

.fieldItem {
    flex          : 0 0 33.333333%;
    display       : flex;
    flex-direction: column;
    padding-left  : 10px;
}

.fieldKey {
    font-weight  : 500;
    font-size    : 12px;
    color        : rgba(159, 161, 177, 0.6);
    margin-bottom: 10px;
    margin-top   : 20px;
}

.fieldValue {
    color    : var(--text-color);
    display  : flex;
    flex-wrap: wrap;
}

.listField {
    display       : flex;
    flex-direction: column;
    color         : var(--text-color);
}

.listFieldItems {
    display  : flex;
    flex-wrap: wrap;
}

.listFieldItem {
    background-color    : rgba(0, 0, 0, 0.2);
    /* border-left      : 1px solid transparent; */
    /* border-left-color: var(--accent-pink); */
    padding             : 7px 10px 7px 10px;
    margin-right        : 10px;
    margin-bottom       : 10px;
    border-radius       : 10px;
}

.cross {
    font-size  : 14px;
    color      : var(--text-color);
    margin-left: 10px;
    cursor     : pointer;
}

.bigCross {
    font-size: 20px;
    color    : rgb(159, 161, 177, 0.5);
    cursor   : pointer;
    position : absolute;
    top      : 10px;
    right    : 15px;
}

.checkBoxParent {
    height     : 40px;
    display    : flex;
    align-items: center;
}