
.root {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  max-height: 100%;
  justify-content: center;
  align-items: center;
  color: rgb(220, 220, 220);
  background-color: var(--home-background);
}
@media (min-width: 672px){
  .host {
    width: 25rem;
}
}


.host{
  visibility: inherit;
  background-color: var(--amplify-background-color);
  /* width: 28.75rem; */
  min-width: 20rem;
  -webkit-font-smoothing: antialiased;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 35px 40px;
  margin-bottom: 20px;
}

.sectionTitle{
  color: var(--amplify-secondary-color);
  font-size: var(--amplify-text-md-sub);
  font-weight: 700;
  margin-bottom: 0.75rem;

}

.sectionSubTitle {
  font-weight: 400;
  font-size: var(--amplify-text-sm);
  color: var(--amplify-grey);
}

.sectionHeader{
  margin: 1rem 0px 1.5rem;
}

.oauthButton{
  background-color: var(--oauth-button-background);
  color: var(--oauth--button-color);
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  color: var(--button-color);
  font-size: var(--amplify-text-sm);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.oauthContent{
  text-align: center;
  display: block;
  padding: 18px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: black;
}