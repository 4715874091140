.root {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--text-color-root);
  }
  
  .header {
    font-size: 30px;
    font-weight: 200;
    margin-left: 20px;
    display: flex;
    align-items: center;
    min-height: var(--header-height);
    
  }
  
  .description {
    margin-bottom: 30px;
    font-weight: 300;
    color: var(--text-color-darker);
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .content {
    
    
    position: relative;
    min-height: 0px;
    flex-grow: 1;
    margin-left: 20px;
    overflow-y: scroll;
    margin-top: 20px;
  }
  
  .link {
    color: var(--link-color);
    cursor: pointer;
    margin-left: 20px;
  }
  
  /* .cardItem {
    background-color: var(--home-background-lighter) !important;
    
  }

  .cardHeader {
    color:rgb(212, 212, 213)  !important;
  }

  .cardsHDescription{
    color: rgb(159, 161, 177)  !important;
  }
  .cardButton{
    background-color: var(--blue-button-background)  !important;
  } */

  .categoryHeader{
    font-size: 20px;
    }

  .categoryColumn{
    border-bottom: 1px solid white;
  }

  .applicationList{
    margin-top: 10px  !important;
  }

  .categoryList {
    cursor: pointer;
  }