.root {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--text-color-root);
  }
  
  .header {
    font-size: 30px;
    font-weight: 200;
    margin-left: 20px;
    display: flex;
    align-items: center;
    min-height: var(--header-height);
  }
  
  .description {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 300;
    color: var(--text-color-darker);
    margin-right: 20px;
  }
  
  .content {
    
    
    position: relative;
    min-height: 0px;
    flex-grow: 1;
    margin-left: 20px;
    margin-top: 20px;
  }

  .column{
      display: flex;
      flex-direction: column;
  }

  