
.root {
    padding-top: var(--header-height);
    height: 100%;
    min-width: var(--leftbar-width);
    display: flex;
    flex-direction: column;
    max-height: 100%;
    background-color: var(--leftbar-background);
    overflow: auto;

    font-size: 14px;
    border-right: 2px solid var(--accent-blue);
  }
  
  .item {
    width: 100%;
    height: var(--leftbar-width);
    padding: 5px;
    color: var(--leftbar-text);
    font-weight: 300;
    cursor: pointer;
    transition: all 0.1s ease;
    border-left: 2px solid transparent;
    border-radius: 3px 0 0 3px;
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .item:hover {
    background-color: var(--sidebar-item-selected);
  }
  
  .selectedItem.item {
    background-color: var(--leftbar-selected-item) ;
   
  }
  
  .item.selectedItem:hover {
    background-color: var(--leftbar-selected-item-hover)  !important;
  }
  
  .icon {
    margin-bottom: 10px;
  }
  
  .label {
    text-align: center;
  }

  